import React from 'react';
import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import OrderListScreen from './screens/OrderListScreen';
import HelpScreen from './screens/HelpScreen';
import TermsConditions from './screens/TermsConditions';
import RegistrationChoice from './screens/RegistrationChoice';
import RegisterScreenSeller from './screens/RegisterScreenSeller';
import ProductListScreenSeller from './screens/ProductListScreenSeller';
import OrderListScreenSeller from './screens/OrderListScreenSeller';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';

const Router = process.env.REACT_APP_RENDER ? HashRouter : BrowserRouter;

function App() {
	return (
		<Router>
			<Header />
			<main>
				<Container>
					<Routes>
						<Route path='/order/:id' element={<OrderScreen />} />
						<Route path='/order/:id/edit' element={<OrderScreen />} />
						<Route path='/shipping' element={<ShippingScreen />} />
						<Route path='/placeorder' element={<PlaceOrderScreen />} />
						<Route path='/payment' element={<PaymentScreen />} />
						<Route path='/login' element={<LoginScreen />} />
						<Route path='/forgot-password' element={<ForgotPasswordScreen />} />
						<Route
							path='/reset-password/:token'
							element={<PasswordResetScreen />}
						/>
						<Route path='/help' element={<HelpScreen />} />
						<Route path='/terms-conditions' element={<TermsConditions />} />
						<Route path='/register' element={<RegisterScreen />} />
						<Route
							path='/registration_choice'
							element={<RegistrationChoice />}
						/>
						<Route path='/register_seller' element={<RegisterScreenSeller />} />
						<Route
							path='/register_seller/individual&redirect'
							element={<RegisterScreenSeller />}
						/>

						<Route path='/profile' element={<ProfileScreen />} />
						<Route path='/product/:id' element={<ProductScreen />} />
						<Route path='/cart/:id?' element={<CartScreen />} />
						<Route path='/admin/userlist' element={<UserListScreen />} />
						<Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
						<Route path='/admin/orderlist' element={<OrderListScreen />} />
						<Route
							path='/seller/productlist'
							element={<ProductListScreenSeller />}
						/>
						<Route
							path='/admin/product/:id/edit'
							element={<ProductEditScreen />}
						/>
						<Route
							path='/seller/orderlist'
							element={<OrderListScreenSeller />}
						/>
						<Route path='/admin/productlist' element={<ProductListScreen />} />
						<Route
							path='/admin/productlist/:pageNumber'
							element={<ProductListScreen />}
						/>
						<Route path='/search/:keyword' element={<HomeScreen />} />
						<Route path='/page/:pageNumber' element={<HomeScreen />} />
						<Route
							path='/search/:keyword/page/:pageNumber'
							element={<HomeScreen />}
						/>
						<Route path='/' element={<HomeScreen />} />
					</Routes>
				</Container>
			</main>
			<Footer />
		</Router>
	);
}

export default App;
